/* global ga, ko, country, currencyInfo */
$(function() {
    $('[data-modal]').modal();
    $('[data-toggle~=tooltip]').tooltip();
    $.pnotify.defaults.styling = 'bootstrap3';
    $.pnotify.defaults.history = false;
    // funnel tracking
    $('#header-register-button').click(function() {
        if (window.ga && ga.create) {
            ga('send', 'pageview', '/accounts/signup/modal');
        }
    });
    $('#signup-form-modal,#login-form-modal').each(function() {
        var input = $('<input>').prop({
            name: 'csrfmiddlewaretoken',
            value: $.cookie('csrftoken'),
            type: 'hidden'
        });
        var div = $('<div>').css('display', 'none').append(input);
        $(this).append(div);
    });
    $('#register-modal').find('input').blur(function() {
        var name = $(this).prop('id').slice(3);
        if (window.ga && ga.create && this.value) {
            ga('send', 'pageview', '/user/signup/' + name);
        }
    });
    $('#signup-form').submit(function() {
        if (window.ga && ga.create) {
            ga('send', 'pageview', '/user/signup/submit');
        }
    });
    // end funnel tracking
});
var acceptCookies = function() {
    $.get('/accept_cookies/', function(data) {
        $('#tindie_cookie_alert').hide();
    });
};
/**
 * Main namespace
 */
var tindie = tindie || {};
tindie.app = (function(t, $) {
    t.CurrencyInfo = function(view, data) {
        var self = this;
        ko.mapping.fromJS(data, {}, self);
    };
    t.base = function(vm) {
        var self = this;
        var countries = tindie.countries;
        self.blurAll = function() {
            document.activeElement.blur();
        };
        self.country = ko.observable(country).extend({
            rateLimit: 100
        });
        self.currencyInfo = ko.observable(new t.CurrencyInfo(self, currencyInfo));
        self.countryName = ko.computed(function() {
            var country = countries.filter(function(element) {
                return element.code == self.country();
            });
            return (country.length > 0 ? country[0].name : '');
        });
        self.hasCountry = ko.computed(function() {
            return (self.country() != null && self.country() !== '' && self.country() !== 'None');
        });
        self.totalItems = ko.observable();
        self.loggedIn = ko.observable($('.active-account').length);
        self.acceptCookies = function() {
            acceptCookies();
        };
        self.registerBox = ko.validatedObservable(new tindie.auth.RegisterBox());
        self.loginBox = ko.validatedObservable(new tindie.auth.LoginBox());
        self.canRegister = ko.computed(function() {
            return self.registerBox.isValid();
        });
        self.canLogin = ko.computed(function() {
            return self.loginBox.isValid();
        });
        self.cancelButton = function(data, event) {
            var button = $(event.target);
            if (button.data('dismiss') === 'modal') {
                return;
            } else if (button.data('cancel-url')) {
                window.location = button.data('cancel-url');
            } else {
                window.history.back();
            }
        };
    };
    /**
     * Method for initializing the app.
     * Init method is executed automatically.
     */
    t.init = function() {
        var s = tindie.search;
        $('body').removeClass('nojs');
        // include libraries
        t.include();
        if ($('.search-tindie').length > 0) {
            s.initSearch();
        }
    };
    /**
     * Method for include and execute namespace init method based on page name
     */
    t.include = function() {
        // include page library
        var page = t.getCommon();
        // execute page namespace
        if (tindie[page] != undefined) {
            tindie[page].init();
        } else if (t.getPage() == 'home') {
            tindie.home.init();
        } else if (t.getPage() == 'product_search') {
            tindie.product_search.init();
        } else if (t.getPage() == 'checkout_complete') {
            tindie.checkout_complete.init();
        } else if (tindie.admin) {
            tindie.admin.init();
        } else {
            var vm = new t.base();
            ko.applyBindings(vm, document.body);
        }
    };
    /**
     * Getter for page namespace
     */
    t.getCommon = function() {
        return $('body').attr('data-url-namespace');
    };
    t.getPage = function() {
        return $('body').attr('data-url-name');
    };
    return t;
})(tindie.app || {}, jQuery);